export default async function auth({ to, next, store }) {
    const loginQuery = { path: "/", query: { redirect: to.fullPath } };
    if ((to.name !== "home" && !store.getters["auth/hasToken"])) {
        next(loginQuery);
        /*try {
           var result = await store.dispatch("auth/ActionCheckToken");
           if(result == undefined){
            next('/')
           }else
            next();
        } catch (error) {
            next(loginQuery)
        }*/
    } else {
        next();
    }
}
  