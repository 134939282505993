import * as types from './mutation-types';
import services from '@/http';
import { cadastro} from '@/http';
import store from '@/store';
//import router from '@/router';

export const ActionUpdateDebtor = (context, payload) => {
    cadastro.updateDebtor.data = payload;
    return services.http.request(cadastro.updateDebtor)
        .then(async result => {
            await store.dispatch('auth/ActionGetUser');

            return result;
        })
        .catch((err) => {
            //router.push({ name: 'home' });
            return err;
        });

}

export const ActionUpdateDebtorPassword = (context, payload) => {
    cadastro.updateDebtorPassword.data = payload;
    return services.http.request(cadastro.updateDebtorPassword)
        .then(async result => {
            return result;
        })
        .catch((err) => {
            return err;
        });
}

export const ActionSetCurrentComponentRegister = ({ commit }, currentComp) => {
    commit(types.SET_CURRENT_COMPONENT, currentComp);
}
