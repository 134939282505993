import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#607d8b',
            secondary: '#414BB2',
            accent: '#8FD14F',
            error: '#f44336',
            warning: '#ff9800',
            info: '#009688',
            success: '#4caf50',
            anchor: '#2D9BF0',
          },
        },
    },
})

export default vuetify
