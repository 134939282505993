export default {
    hasAuthUser: state => state.user !== null,
    hasToken: state => !!state.token,
    getToken: state => state.token,
    hasError: state => state.error ,
    isLoggedIn: state => !!state.user,
    isGuest: () => {
        const storageItem = window.localStorage.getItem("guest");
        if (!storageItem) return false;
        if (storageItem === "isGuest") return true;
        if (storageItem === "isNotGuest") return false;
    }
}