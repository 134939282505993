import auth from "@/middleware/auth";

export default [
    {
        path: "negociarDivida",
        name: "negociarDivida",
        meta: { middleware: [auth] },
        component: () => import("./NegociarDivida")
    }
];
