import guest from "@/middleware/guest";

export default [
    {
        path: "termos",
        name: "termos",
        meta: { middleware: [guest] },
        component: () => import("./Termos")
    }
];
