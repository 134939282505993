import guest from "@/middleware/guest";

export default [
  {
      path: "",
      component: () => import(/* webpackChunkName: "DashboardLayout" */ "@/modules/layout/HomeLayout"),
      children: [
          {
              path: "/",
              name: "home",
              meta: { middleware: [guest] },
              component: () => import(/* webpackChunkName: "home" */ "./Home"),
          }
      ]
  },
];
