import guest from "@/middleware/guest";

export default [
    {
        path: "experimente",
        name: "experimente",
        meta: { middleware: [guest] },
        component: () => import("./Experimente")
    }
];
