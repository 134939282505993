import * as types from './mutation-types';

export default {
    [types.SET_USER](state, user) {
        state.user = user;
    },
    [types.SET_WHATSTEXT](state, text) {
        state.whatstext = text;
    },
    [types.SET_TOKEN](state, token) {
        state.token = token;
    },
    [types.SET_LOADING](state, loading) {
        state.loading = loading;
    },
    [types.SET_ERROR](state, error) {
        state.error = error;
    },
    [types.SET_CURRENTFORM](state, currentForm) {
        state.currentForm = currentForm;
    },
    [types.SET_CONTACTS](state, contacts) {
        state.contacts = contacts;
    },
}