import auth from "@/middleware/auth";

export default [
    {
        path: "meus-boletos",
        name: "meusBoletos",
        meta: { middleware: [auth] },
        component: () => import(/* webpackChunkName: "minhas-dividas" */ "./MeusBoletos"),
    }
];
