import auth from "@/middleware/auth";

export default [
    {
        path: "meus-acordos",
        name: "meusAcordos",
        meta: { middleware: [auth] },
        component: () => import(/* webpackChunkName: "meus-acordos" */ "./MeusAcordos"),
    },
    {
        path: "meus-acordos/acordo/:acordo_id",
        name: "acordo",
        meta: { middleware: [auth] },
        component: () => import(/* webpackChunkName: "acordo" */ "./Acordo"),
    }
];
