import auth from "@/middleware/auth";

export default [
    {
        path: "cadastro",
        name: "cadastro",
        meta: { middleware: [auth] },
        component: () => import("./Cadastro"),
    }
];
