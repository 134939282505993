export default async function guest({ next, store }) {
    const storageItem = window.localStorage.getItem("guest");
    if (storageItem === "isNotGuest" && !store.getters["auth/hasToken"]) {
        try {
            await store.dispatch("auth/ActionCheckToken");
            if (store.getters["auth/hasToken"]) {
                next({ name: "dashboard" });
            } else {
                store.dispatch("auth/ActionSetGuest", { value: "isGuest" });
                next({ name: "home"});
            }
        } catch (error) {
            store.dispatch("auth/ActionSetGuest", { value: "isGuest" });
            next({ name: "home"});
        }
    } else {
        next();
    }
}
  